import { createRouter, createWebHistory } from 'vue-router'
import Body from './../components/HomeApp.vue'
import QuienesSomos from './../components/QuienesSomos.vue'
import QueHacemos from './../components/QueHacemos.vue'
import Noticias from './../components/NoticiasApp.vue'
import Contactanos from './../components/ContactanosApp.vue'
import Articulo from './../components/ArticuloNoticia.vue'
import Security from '../components/security'


const routes = [
    {
        path: '/',
        name: 'Home',
        component: Body,
    },
    {
        path: '/quienes-somos',
        name: 'QuienesSomos',
        component: QuienesSomos,
    },
    {
        path: '/nuestros-servicios',
        name: 'QueHacemos',
        component: QueHacemos,
    },
    {
        path: '/noticias',
        name: 'Noticias',
        component: Noticias,
    },
    {
        path: '/contactanos',
        name: 'Contactanos',
        component: Contactanos,
    },
    {
        path: '/articulo/:id',
        name: 'Articulo',
        component: Articulo,
    },
]

const router = createRouter({
    history: createWebHistory(), 
    routes
})

// this function will run every time a user navigates to any part of the site,
// and call checkToken(). If the user has a token in store.token, then we validate 
// that token.
router.beforeEach(() => {
    Security.checkToken();
})

export default router