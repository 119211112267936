<template>
    <!-- <div class="d-flex justify-content-center" style="width:100%; ">
        <img src="@/assets/EnConstruccion-transformed.jpeg" style="border-radius: 15px; width:65%;" alt="Página Ahdeco">
    </div> -->


    <div class="container">
        <h2 class="mb-5 display-3 bebas-neue-regular mt-4 text-center">Menú</h2>

        <div class="row hidden" id="emprendimiento">
            <div class="col-12 col-sm-6 d-flex text-justify justify-content-center flex-column p-5 emprendimiento-info">
                <h4>COFFE BREAK (AM/PM)</h4>
                <p>Este incluye 3 bocadillos salados y uno dulce <br>
                    jugo natural o café. <br>
                    Mini sándwich de jamón y queso <br>
                    Mini sándwich de pasta de pollo <br>
                    Mini hamburguesa <br>
                    Mini pastelitos de carne <br>
                    Mini taquitos <br>
                    Mini catrachas <br>
                    Mini donas <br>
                    Mini cup cake <br>
                    Pincho de frutas </p>
            </div>
            <div class="col-12 col-sm-6 p-5">
                <img class="img-empre" src="@/assets/PHOTO-2023-11-13-14-09-11-3.jpg"
                    alt="emprendimiento comunitario ahdeco">
            </div>
        </div>
        <div class="row hidden" id="gobierno">
            <div class="col-12 order-2 order-sm-1 col-sm-6 p-5 ">
                <img class="img-empre" src="@/assets/PHOTO-2024-03-03-07-49-52.jpg" alt="emprendimiento comunitario ahdeco">
            </div>
            <div
                class="col-12 order-1 order-sm-2 col-sm-6 d-flex text-justify justify-content-center flex-column p-5 gobierno-info">
                <h4 class="mt-3">ALMUERZOS</h4>
                <b>CARNES BLANCAS</b>
                <p>
                    Pollo empanizado <br>
                    Pollo al horno <br>
                    Pollo en salsa blanca con pollo <br>
                    a la plancha en salsa barbacoa<br>
                    Pollo a la naranja<br>
                    Fajitas de pollo con pimientos<br>


                </p><br>
                <b>CARNES ROJAS</b>
                <p>Albóndigas de res en salsa roja<br>
                    Bistec encebollado<br>
                    Fajitas de res<br>
                    Medallones de cerdo<br>
                    Cos lla de cerdo en salsa barbacoa<br>
                    Chuleta de cerdo asada con chimichurri</p><br>
                <b>GUARNICIONES</b>
                <p>Arroz cal curry<br>
                    Arroz a la jardinera<br>
                    Arroz con maíz<br>
                    Ensalada de papa en mayonesa<br>
                    Ensalada verde con su vinagreta<br>
                    Pure de papa<br>
                    Pure de camote<br>
                    Ensalada de vegetales al vapor</p>
            </div>

        </div>
        <!-- <div class="row hidden" id="comunidad">
            <div class="col-12 col-sm-6 d-flex text-justify justify-content-center flex-column p-5 comunidad-info">
                <h4 class="mt-3"> MI COMUNIDAD UNIDA </h4>
                <p>Promover el desarrollo social y la inclusión en la comunidad, a través de programas y proyectos que
                    fortalezcan la educación, la salud sexual y reproductiva, la prevención de la violencia doméstica,
                    la
                    contención de la migración y la atención al migrante retornado, la igualdad de género y la
                    participación
                    ciudadana.
                </p>
            </div>
            <div class="col-12 col-sm-6 p-5">
                <img class="img-empre" src="@/assets/comunidad.jpeg" alt="emprendimiento comunitario ahdeco">
            </div>
        </div> -->

        <p>Nota: El plato está acompañado de una carne de su elección más dos guarniciones y
            refresco <br> Ejemplo de un menú: pollo al horno, ensalada verde, arroz al curry + refresco</p>

    </div>

</template>

<script>
export default {

}
</script>

<style>
.text-justify {
    text-align: justify;
}

.img-empre {
    width: 100%;
    border-radius: 5px;
}

.bebas-neue-regular {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #EBB22F;
}

.emprendimiento-info {
    /* border-bottom: 4px solid #158B37; */
    border-left: 4px solid #F6A500;
    /* border-radius: 5px; */
}

.gobierno-info {
    /* border-bottom: 4px solid #1E83C5;        */
    border-right: 4px solid #1E83C5;
    /* border-radius: 5px; */
}

.comunidad-info {
    /* border-bottom: 4px solid #E20413;    */
    border-left: 4px solid #F6A500;
    /* border-radius: 5px; */
}
</style>

<style scoped>
b{
    font-weight: 600;
}
</style>