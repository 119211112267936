<template>
  <footer class="d-flex flex-column align-items-center justify-content-center mt-5">
    <div class="footer-top">
      <div class="footer-logo">
        <img src="@/assets/logo2.png" alt="WhatsApp Logo" class="logo m-2" />
      </div>

    </div>
    <div class="footer-middle row p-2 p-sm-0">
      <div class="footer-section order-2 order-sm-1 col-lg-3 col-6">
        <h3>¿Quiénes somos?</h3>
        <ul>
          <li><a href="/quienes-somos" onclick="closef()" class="enlace">Acerca de nosotros</a> </li>
          <!-- <li><a href="/quienes-somos" onclick="closef()" class="enlace">Misión</a> </li>
          <li><a href="/quienes-somos" onclick="closef()" class="enlace">Visión</a> </li>
          <li><a href="/quienes-somos" onclick="closef()" class="enlace">Valores</a> </li> -->
        </ul>
      </div>
      <div class="footer-section order-1 order-sm-2 col-lg-3 col-6">
        <h3>Lo que hacemos</h3>
        <ul>
          <li><a href="/que-hacemos" onclick="closef()" class="enlace">CATERING</a> </li>
          <li><a href="/que-hacemos" onclick="closef()" class="enlace">EVENTOS</a> </li>
          <li><a href="/que-hacemos" onclick="closef()" class="enlace">COMIDA SALUDABLE</a> </li>
        </ul>
      </div>
      <div class="footer-section order-4 order-sm-3 mt-2 mt-sm-0 mapa col-lg-3 col-6">
        <!-- <h3>Ubicación</h3> -->
        <div class="mapa">
          <!-- <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3254.0186182872103!2d-87.1925338795!3d14.09475517711527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f6fa2c562e9cf89%3A0xa0738e1022af0daa!2s3RW4%2B6CG%2C%20Tegucigalpa%2C%20Francisco%20Moraz%C3%A1n!5e0!3m2!1ses!2shn!4v1724361708027!5m2!1ses!2shn"
          width="100" height="100%" style="border:0;" allowfullscreen="" loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"></iframe> -->
        </div>
        

      </div>
      <div class="footer-section order-3 mt-2 mt-sm-0 order-sm-4 col-lg-3 col-6">
        <h3>Contáctanos</h3>
        <ul>
          <!-- <li class="info-dir">Col. Rubén Darío, Casa 0592 Tegucigalpa, Honduras</li> -->
          <li class="info-dir">encargos@antojitoscatrachos.hn</li>
          <li class="info-dir">+504 8819-8378</li>
        </ul>
      </div>
    </div>
    <div class="footer-bottom mb-3">
      <p class="ms-3 ms-sm-0">2024 © ANTOJITOS CATRACHOS</p>
      <ul class="footer-links">
        <!-- <li>Condiciones y Política de privacidad</li> -->
        <!-- <li>Mapa del sitio</li> -->
      </ul>
      <div class="social-icons">
        <!-- <a href="https://twitter.com/ahdecohn" target="_blank" class="me-3 fa-brands fa-x-twitter"></a> -->
        <a href="https://www.instagram.com/antojitoscatrachoshn?igsh=dTZlcWZ2bHBqOWNx&utm_source=qr" target="_blank" class="fa-brands fa-instagram"></a>
        <a href="https://www.facebook.com/profile.php?id=100038914729251" target="_blank" class="me-3 fa-brands fa-facebook"></a>
      </div>
      <!-- <button class="language-button">
        español
        <i class="fas fa-chevron-down"></i>
      </button> -->
    </div>
  </footer>
</template>

<script>

export default {
  data() {
    return {
      text: ''
    }
  },

}

</script>

<style scoped>
footer {
  /* margin-top: 60px; */
  background-color: #1c1e21;
  color: white;
  min-height: 3em;
  font-size: 0.8em;
}

.footer {
  background-color: #1c1e21;
  color: #e0e0e0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.footer-logo .logo {
  width: 150px;
  background-color: white;
  border-radius: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
}

.download-button {
  background-color: #25d366;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.1em;
  display: flex;
  align-items: center;
}

.download-button i {
  margin-left: 10px;
}

.footer-middle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.footer-section {
  /* flex: 1; */
  /* margin: 0 20px; */
  padding: 0 20px;
}

.footer-section h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #ffffff;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section li {
  margin-bottom: 10px;
  color: #a9a9a9;
  cursor: pointer;
}

.footer-section li:hover {
  text-decoration: underline;
}

.footer-bottom {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #333;
  padding-top: 20px;
}

.footer-links {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.footer-links li {
  margin-right: 20px;
  cursor: pointer;
  color: #a9a9a9;
}

.footer-links li:hover {
  text-decoration: underline;
}

.social-icons i {
  font-size: 1.5em;
  margin-right: 15px;
  color: #a9a9a9;
  cursor: pointer;
}

.social-icons i:hover {
  color: #ffffff;
}

.language-button {
  background-color: transparent;
  color: #e0e0e0;
  border: 1px solid #ffffff;
  padding: 5px 15px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.language-button i {
  margin-left: 5px;
}

iframe{
  width: 100%;
  height: 100%;
}

.enlace{
  text-decoration: none;
  color: #a9a9a9;
}
.fa-brands{
  font-size: 1.5em;
  margin-right: 15px;
  color: #a9a9a9;
  cursor: pointer;
  text-decoration: none;
}

.info-dir:hover{
  text-decoration: none;
  cursor: text;
}
.info-dir{
  text-decoration: none !important;
  cursor: text;
}
</style>
